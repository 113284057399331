body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
  display: none
}

.Toastify__toast {
  align-items: flex-start;
  border: 1px solid #000;
  border-radius: 25px !important;
}
.Toastify__toast > button {
  position: relative;
  top: -8px;
  right: -5px;
}
.Toastify__toast-icon {
  width: 30px !important;
}
.Toastify__toast-body {
  color: #1C1917 !important;
  font-family: "Roboto Flex" !important;
}

.ck.ck-editor__main {
  margin-top: 15px;
}
.ck.ck-content, .ck.ck-toolbar__items, .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: 1px !important;
  border-color: #BFBFBF !important;
  border-style: solid !important;
  border-radius: 24px !important;
  box-shadow: none !important;
}

.ck.ck-toolbar__items {
  flex-grow: 0 !important;
  background-color: #f0f0f3 !important;
  border-color: #1C191B !important;
  padding: 6px !important;
}

.ck.ck-sticky-panel__content {
  border: 0 !important;
}

.ck.ck-button.ck-on, a.ck.ck-button.ck-on {
  background-color: #e0e1e6 !important;
  color: #1c2024 !important;
}

.ck.ck-button:active, a.ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:focus, .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on, .ck.ck-button.ck-button_with-text, a.ck.ck-button.ck-button_with-text {
  border: 0 !important;
  box-shadow: none !important;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

/* .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover {
  background-color: #e0e1e6 !important;
} */


::-webkit-scrollbar {
  width: 6px; 
  background-color: rgba(0, 0, 0, 0.1); 
}
::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.6); 
  border-radius: 6px; 
}
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.mdxeditor {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mdxeditor [role=toolbar] {
  border: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: #1C1917;
  width: 200px;
}

.mdxeditor [role=textbox] {
  border: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);
  min-height: 125px;
}

.error.mdxeditor [role=textbox] {
  border-color: #d32f2f;
}

.mdxeditor [role=textbox] p, .mdxeditor [class^=_contentEditable] p {
  margin-top: 0;
}

.mdxeditor div > button[title="Underline"] {
  display: none;
}

fieldset.MuiOutlinedInput-notchedOutline legend .MuiStack-root span {
  display: none;
}